@import url('https://fonts.googleapis.com/css2?family=Sigmar+One&display=swap');
@import url('https://fonts.googleapis.com/css?family=Press+Start+2P');

body {
  margin: 0;
}

.Polaris-TextField__VerticalContent {
  overflow: hidden;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.Polaris-DatePicker__MonthLayout {
  margin-top: 0px;
}
.date-picker-btn-group {
  display: flex;
  justify-content: flex-end;
}
.Polaris-PositionedOverlay {
  z-index: 510;
}
.flex-end {
  justify-content: flex-end;
}
.ml-8 {
  margin-left: 8px;
}
.mt-16 {
  margin-top: 1rem;
}
.mt-32 {
  margin-top: 2rem;
}
.mt-8 {
  margin-top: 8px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 1rem;
}
.mr-8 {
  margin-right: 0.5rem;
}
.mr-4 {
  margin-right: 0.5rem;
}
.mb-8 {
  margin-bottom: 0.5rem;
}
.mb-4 {
  margin-bottom: 0.25rem;
}
.my-16 {
  margin: 1rem 0;
}
.p-8 {
  padding: 0.5rem;
}
.p-16 {
  padding: 1rem;
}
.pd-20 {
  padding: 20px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pd-8 {
  padding: 0.5rem;
}
.pl-16 {
  padding-left: 1rem;
}
.ml-4 {
  margin-left: 4px;
}
.link-no-monochrome .Polaris-Link {
  color: rgba(0, 91, 211, 1);
}
select {
  padding-left: 1rem;
}
.break-word {
  overflow-wrap: break-word;
  word-break: break-all;
}
.text-align {
  text-align: center;
}
.Polaris-IndexTable__TableRow {
  cursor: auto;
}
.pointer {
  cursor: pointer;
}
.space-between {
  display: flex;
  justify-content: space-between;
}

/* .Polaris-IndexTable__TableCell--first .Polaris-IndexTable-Checkbox__TableCellContentContainer {
  display: none !important;
} */
/* .Polaris-IndexTable__TableHeading--first {
  padding: 0 !important;
  max-width: 0 !important;
}
.Polaris-IndexTable__TableCell--first {
  padding: 0 !important;
  max-width: 0 !important;
}
.Polaris-IndexTable__StickyTableHeader--isSticky {
  visibility: hidden;
} */

.Polaris-Pagination {
  display: flex;
  justify-content: center;
}
.lh-32 .Polaris-Text--root {
  line-height: 2rem !important;
}
.disabled {
  pointer-events: none;
  opacity: 0.4;
}

@media screen and (max-width: 768px) {
  .lh-32 .Polaris-Text--root {
    line-height: 2.25rem !important;
  }
}

@media screen and (max-width: 490px) {
  .Polaris-IndexFilters__IndexFiltersSticky {
    display: none;
  }
  .Polaris-IndexTable__TableHeading--first .Polaris-Checkbox__Backdrop {
    display: none;
  }
}

.help-center-content {
  padding: 2rem 1rem;
}
.help-center-content .Polaris-Icon {
  margin: 0;
}

.slick-track {
  display: flex;
  align-items: stretch;
}
.slick-track .slick-slide {
  padding: 0 0.5rem;
}

.slick-slide {
  height: auto;
}
.slick-slide > div {
  height: 100%;
}
.slick-slide .Polaris-ShadowBevel {
  height: 100%;
}
.slick-slide .Polaris-ShadowBevel .Polaris-Box {
  display: flex;
}
.help-center-slide-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.help-center-text {
  padding: 1rem;
}
.help-center-btn {
  padding: 0.5rem;
  padding-left: 1rem;
  background-color: rgb(241 241 241);
}
.Polaris-Popover__Pane {
  scrollbar-width: none; /* Ẩn thanh cuộn trên Firefox */
}
.Polaris-Popover__Pane::-webkit-scrollbar {
  display: none;
}

.Polaris-Choice.Polaris-Checkbox__ChoiceLabel {
  align-items: start;
  .Polaris-Choice__Control {
    margin-top: 1px;
  }
}

@media (max-width: 47.9975em) {
  .Polaris-Modal-Dialog__Modal {
    /* Đưa modal lên giữa màn hình ở màn mobile */
    bottom: 50%;
    transform: translateY(50%);
  }
}
